import { DefaultResponse } from './default-response';

export enum MeasurementSystem {
  IMPERIAL = 'imperial',
  METRIC = 'metric',
}

// Although the user preferences can technically be any arbitrary object,
// the preferences tab currently only supports editing:
// System of Measurement
// Timezone Offset
export interface UserPrefs {
  systemOfMeasurement: MeasurementSystem;
  timezone: number;
}

export interface GetUserPrefsDTO {
  user_id: number;
  prefs: UserPrefs;
}

export interface SetUserPrefsDTO {
  prefs: UserPrefs;
}

export interface UpdateUserPrefsDTO {
  prefs: UserPrefs;
}

export interface UserPrefsResponse extends DefaultResponse {
  user_id: number;
  prefs: UserPrefs;
}
