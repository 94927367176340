import {
  fetchUserPrefs,
  setUserPrefs,
  updateUserPrefs,
} from '@/api/accidentplan-api';
import { MeasurementSystem, UserPrefs } from '@/api/dto/user-prefs';
import { FetchStates } from '@/helpers/fetch-states';
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '.';
import { AuthModule, AuthStates } from './auth';

@Module({
  store,
  name: 'UserPrefsVuexModule',
  namespaced: true,
  dynamic: true,
})
export class UserPrefsVuexModule extends VuexModule {
  // state variables
  fetchState: FetchStates = FetchStates.UNFETCHED;
  prefs: UserPrefs | null = null;

  // actions
  @Action
  async fetchUserPrefs() {
    // If we're not properly authenticated, just exit (we can't make this request)
    if (AuthModule.authState !== AuthStates.LOGGED_IN) {
      return;
    }

    // Otherwise, make the proper API request to get the user preferences
    const userPrefsResponse = await fetchUserPrefs();
    const prefs = userPrefsResponse.prefs;

    // If certain prefs are not set, we need to set them to default values
    if (!prefs?.systemOfMeasurement || !prefs?.timezone) {
      // Creating the default prefs object from the existing preferences
      prefs.systemOfMeasurement =
        prefs.systemOfMeasurement ?? MeasurementSystem.IMPERIAL;
      prefs.timezone = prefs.timezone ?? new Date().getTimezoneOffset() / -60;

      // Pushing the new prefs to the API
      try {
        await this.setUserPrefs(prefs);
      } catch (error) {
        console.error(error);
      }
    } else {
      this.SET_PREFS(prefs);
    }

    return this.prefs;
  }

  @Action
  async setUserPrefs(prefs: UserPrefs) {
    // If we're not properly authenticated, just exit (we can't make this request)
    if (AuthModule.authState !== AuthStates.LOGGED_IN) {
      return;
    }

    // Otherwise, make the proper API request to set the user preferences
    await setUserPrefs(prefs);
    const userPrefsResponse = await fetchUserPrefs();
    this.SET_PREFS(userPrefsResponse.prefs);

    return this.prefs;
  }

  @Action
  async updateUserPrefs(prefs: UserPrefs) {
    // If we're not properly authenticated, just exit (we can't make this request)
    if (AuthModule.authState !== AuthStates.LOGGED_IN) {
      return;
    }

    // Otherwise, make the proper API request to set the user preferences
    await updateUserPrefs(prefs);
    const userPrefsResponse = await fetchUserPrefs();
    this.SET_PREFS(userPrefsResponse.prefs);

    return this.prefs;
  }

  @Action
  async getUserPrefs() {
    if (!this.prefs?.systemOfMeasurement || !this.prefs?.timezone) {
      await this.fetchUserPrefs();
    }

    return this.prefs;
    //return null;
  }

  // mutations
  @Mutation
  SET_PREFS(prefs: UserPrefs) {
    this.prefs = prefs;
  }

  @Mutation
  SET_FETCH_STATE(fetchState: FetchStates) {
    this.fetchState = fetchState;
  }
}

export const UserPrefsModule = getModule(UserPrefsVuexModule);
