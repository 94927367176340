import { AuthModule } from '@/store/auth';
import { tryRequest } from '.';
import {
  CloseCrashFileResponse,
  CrashFileQueryDTO,
  GetCrashFileResponse,
  SearchCrashFileResponse,
} from './dto/crash-file.dto';
import { ExportRequestResponse } from './dto/export.dto';
import {
  ToggleIntegrationsDTO,
  ToggleIntegrationsResponse,
} from './dto/integrations.dto';
import {
  GetIntegrationsResponse,
  GetOrgConfigResponse,
} from './dto/rb-config.dto';
import {
  CloseReportResponse,
  GetReportResponse,
  GetTagsResponse,
  RBRecordDTO,
  GetTagValuesResponse,
  ReportQueryDTO,
  SearchReportMetadataReponse,
  TagTypeEnum,
} from './dto/report';
import {
  CreateWorkflowPayload,
  DeleteWorkflowReponse,
  GetWorkflowResponse,
  GetWorkflowsResponse,
  UpdateWorkflowPayload,
  WorkflowDTO,
} from './dto/workflow.dto';
import { API_BASE_URL } from './urls';
import {
  AddInfoAddRecordResponse,
  AddInfoCreateResponse,
  AddInfoRecord,
  AddInfoRecordResponse,
} from './dto/add-info.dto';

export const baseURL = API_BASE_URL;

export async function createAdditionalReport(crashfile_id: string) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<AddInfoCreateResponse>(
      fetch(
        `${baseURL}/integrations/additional_information/create-new-report/${crashfile_id}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.reportId;
}

export async function AddInfo_getFile(report_id: string, file_key: string) {
  const token = await AuthModule.getAccessToken();
  const response = await
    fetch(`${baseURL}/integrations/additional_information/${report_id}/file/${file_key}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  if (response.status >= 400) throw response;
  return await response.blob();
}

export async function AddInfo_addRecord(
  report_id: string,
  record: AddInfoRecord,
) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<AddInfoAddRecordResponse>(
      fetch(
        `${baseURL}/integrations/additional_information/${report_id}/record`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify({ name: record.name, note: record.note }),
        },
      ),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.recordId;
}

export async function AddInfo_removeRecord(
  report_id: string,
  record_id: string,
) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<AddInfoAddRecordResponse>(
      fetch(
        `${baseURL}/integrations/additional_information/${report_id}/record/${record_id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
        },
      ),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.recordId;
}

export async function AddInfo_updateRecord(
  report_id: string,
  record_id: string,
  record: AddInfoRecord,
) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<AddInfoRecordResponse>(
      fetch(
        `${baseURL}/integrations/additional_information/${report_id}/record/${record_id}`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify({ note: record.note }),
        },
      ),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response;
}

export async function AddInfo_addImageToRecord(
  report_id: string,
  record_id: string,
  file: File,
) {
  const token = await AuthModule.getAccessToken();
  const formData = new FormData();
  formData.append('image', file);
  const response = (
    await tryRequest<AddInfoRecordResponse>(
      fetch(
        `${baseURL}/integrations/additional_information/${report_id}/record/${record_id}/image`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: 'POST',
          body: formData,
        },
      ),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response;
}

export async function fetchReportList(reportQuery: ReportQueryDTO) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<SearchReportMetadataReponse>(
      fetch(`${baseURL}/reports/search`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify(reportQuery),
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response;
}

export async function fetchFullReport(id: string) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetReportResponse>(
      fetch(`${baseURL}/reports/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response;
}

export async function closeReport(id: string) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<CloseReportResponse>(
      fetch(`${baseURL}/reports/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response;
}

export async function fetchReportTags(searchStr: string, orgIds: number[]) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetTagsResponse>(
      fetch(
        `${baseURL}/reports/tags/?org_ids=${orgIds.join(',')}
        ${
          !searchStr || searchStr.length === 0 ? '' : `&name_query=${searchStr}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.tags;
}

export async function fetchCrashFileList(reportQuery: CrashFileQueryDTO) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<SearchCrashFileResponse>(
      fetch(`${baseURL}/crashfile/search`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify(reportQuery),
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response;
}

export async function fetchCrashFile(id: string) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetCrashFileResponse>(
      fetch(`${baseURL}/crashfile/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.crashFile;
}

export async function closeCrashFile(id: string) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<CloseCrashFileResponse>(
      fetch(`${baseURL}/crashfile/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.crashFile;
}

export async function fetchCrashFileTags(searchStr: string, orgIds: number[]) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetTagsResponse>(
      fetch(
        `${baseURL}/crashfile/tags/?org_ids=${orgIds.join(',')}
        ${
          !searchStr || searchStr.length === 0 ? '' : `&name_query=${searchStr}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.tags;
}

export async function fetchCrashFileTagValues(
  tagName: string,
  orgIds: number[],
) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetTagValuesResponse>(
      fetch(
        `${baseURL}/crashfile/tag-values/?org_ids=${orgIds.join(',')}
        ${!tagName || tagName.length === 0 ? '' : `&name_query=${tagName}`}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.values;
}

export async function fetchWorkflowList(orgIds: number[]) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetWorkflowsResponse>(
      fetch(`${baseURL}/workflow/?orgIds=${orgIds.join(',')}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.workflows;
}

export async function fetchSingleWorkflow(id: string) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetWorkflowResponse>(
      fetch(`${baseURL}/workflow/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.workflow;
}

export async function createSingleWorkflow(workflow: CreateWorkflowPayload) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetWorkflowResponse>(
      fetch(`${baseURL}/workflow`, {
        method: 'POST',
        body: JSON.stringify(workflow),
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.workflow;
}

export async function updateSingleWorkflow(workflow: UpdateWorkflowPayload) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetWorkflowResponse>(
      fetch(`${baseURL}/workflow`, {
        method: 'PATCH',
        body: JSON.stringify(workflow),
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.workflow;
}

export async function deleteMultipleWorkflows(ids: string[]) {
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<DeleteWorkflowReponse>(
      fetch(`${baseURL}/workflow?ids=${ids}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.delete_count;
}

export async function getOrgEnabledIntegrations(orgId: number) {
  // Response defined as "GetOrgConfigResponse" in report builder
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetOrgConfigResponse>(
      fetch(`${baseURL}/org/config?org_id=${orgId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.integrations;
}

export async function getAvailableIntegrations() {
  // Reponse defined as "GetIntegrationsResponse" in report builder
  const token = await AuthModule.getAccessToken();
  const response = (
    await tryRequest<GetIntegrationsResponse>(
      fetch(`${baseURL}/integrations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.integrations;
}

export async function enableIntegrations(toggleDTO: ToggleIntegrationsDTO) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<ToggleIntegrationsResponse>(
      fetch(`${baseURL}/integrations/enable/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify(toggleDTO),
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.integrations;
}

export async function disableIntegrations(toggleDTO: ToggleIntegrationsDTO) {
  const token = await AuthModule.getAccessToken();
  const resp = (
    await tryRequest<ToggleIntegrationsResponse>(
      fetch(`${baseURL}/integrations/disable/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify(toggleDTO),
      }),
    )
  ).data;

  if (resp.statusCode >= 400) throw resp;

  return resp.integrations;
}

export async function requestReportExport(reportId: string) {
  const token = await AuthModule.getAccessToken();

  const response = (
    await tryRequest<ExportRequestResponse>(
      fetch(`${baseURL}/export/report/${reportId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.request;
}

export async function requestMultiReportExport(reportIds: string[]) {
  const token = await AuthModule.getAccessToken();

  const response = (
    await tryRequest<ExportRequestResponse>(
      fetch(`${baseURL}/export/report?reportIds=${reportIds.join(',')}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.request;
}

export async function requestCrashFileExport(crashfileId: string) {
  const token = await AuthModule.getAccessToken();

  const response = (
    await tryRequest<ExportRequestResponse>(
      fetch(`${baseURL}/export/crashfile/${crashfileId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    )
  ).data;

  if (response.statusCode >= 400) throw response;

  return response.request;
}

export function downloadExport(nonce: string) {
  const downloadUrl = `${baseURL}/export/download/${nonce}`;
  window.open(downloadUrl, '_blank');
}
