
import {
  defineComponent,
  Ref,
  ref,
  onMounted,
  defineAsyncComponent,
  markRaw,
  Component,
} from 'vue';
import { IntegrationAndReports } from '@/composables/useCrashFileDetail';
import { FetchStates } from '@/helpers/fetch-states';
import { convertDateString } from '@/helpers/date-format';
import { UserPrefs } from '@/api/dto/user-prefs';
import { UserPrefsModule } from '@/store/userprefs';
import router from '@/router';
import { GetReportResponse, ReportMetadataDTO } from '@/api/dto/report';
import { CrashFileDTO } from '@/api/dto/crash-file.dto';
import {
  fetchCrashFile,
  fetchFullReport,
} from '@/api/report-builder';

const DefaultUI = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "ReportNoUI" */ '@/components/integrations/default/NoUI.vue'
  ),
);

const AccidentPlanReport = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "AccidentPlanReport" */ '@/components/integrations/accident-plan/AccidentPlanReport.vue'
  ),
);
const OpenWeatherMapReport = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "OpenWeatherMapReport" */ '@/components/integrations/openweather/WeatherReport.vue'
  ),
);

const GeotabUserReport = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "GeotabUserReport" */ '@/components/integrations/geotab/UserReport.vue'
  ),
);

const GeotabDownloadableReport = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "GeotabDownloadableReport" */ '@/components/integrations/geotab/DownloadableReport.vue'
  ),
);
const integrationCompMap: Record<string, any> = {
  none: DefaultUI,
  open_weather_map_default_ui: OpenWeatherMapReport,
  accidentplan_default_ui: AccidentPlanReport,
  geotab_user_info_ui: GeotabUserReport,
  geotab_csv_download_ui: GeotabDownloadableReport,
  additional_information_ui: null
};
export interface PrintableElement {
  component: Component;
  report: ReportMetadataDTO;
  records: IntegrationAndReports;
  fullReport: Ref<GetReportResponse | null>;
}
export default defineComponent({
  name: 'CrashFilePagePrint',
  components: {
    DefaultUI,
    AccidentPlanReport,
    OpenWeatherMapReport,
    GeotabUserReport,
    GeotabDownloadableReport,
  },
  data() {
    return {
      reports: [] as Array<PrintableElement>,
      fileId: null as Ref<string | null>,
      FetchStates,
      crashFile: null as Ref<CrashFileDTO | null>,
      fetchState: FetchStates.FETCHING
    };
  },
  setup() {
    const fileId: Ref<string | null> = ref(null);
    const userPrefs: Ref<UserPrefs | null> = ref(null);
    const routeBack = () => {
      if (window.history.state.back === null) {
        router.push({ name: 'CrashFilesPage' });
      } else {
        router.back();
      }
    };
    onMounted(async () => {
      (fileId.value = router.currentRoute.value.params['id'] as string),
        (userPrefs.value = await UserPrefsModule.getUserPrefs());
    });
    const printReport = () => {
      window.print();
    };

    return {
      fileId,
      convertDateString,
      userPrefs,
      routeBack,
      printReport,
    };
  },
  methods: {
    async getReportsByIntegration(mCrashFile: CrashFileDTO) {
      const reports = mCrashFile.reports;
      if (!reports) return [];
      const s = await Promise.all(
        reports.map(async report => {
          const fullReportData = await this.fetchReport(report._id);
          if (fullReportData.statusCode == 200) {
            let component = integrationCompMap[report.ui]
            if (component == null) {
              return null;
            }
            return {
              component: markRaw( component as Component),
              report: fullReportData.report,
              records: fullReportData.records,
              fullReport: fullReportData,
            } as PrintableElement;
          }
        }),
      );
      return s.filter(x => x != null) as PrintableElement[];
    },
    async fetchRBCrashFile(id: string) {
      this.fetchState = FetchStates.FETCHING;

      try {
        const resp = await fetchCrashFile(id);
        this.crashFile = resp;

        this.reports = await this.getReportsByIntegration(resp);

        this.fetchState = FetchStates.FETCHED;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchReport(id: string) {
      this.fetchState = FetchStates.FETCHING;

      try {
        const resp = await fetchFullReport(id);
        this.fetchState = FetchStates.FETCHED;
        return resp;
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    fileId(val, old) {
      this.fileId = val;
      this.fetchRBCrashFile(val);
    },
  },
});
