function convertDateString(dateStr: string, utcOffset = 0): string {
  // Making a Date object from the given time string
  const dateObj = new Date(dateStr);

  // If the date is invalid, just return the date string as is
  if (dateObj.toDateString() == 'Invalid Date') {
    return dateStr;
  }

  // Adding an offset to the Date object to account for the given time zone
  const msOffset = utcOffset * 60 * 60 * 1000;
  const dateObjWithOffset = new Date(dateObj.getTime() + msOffset);

  // Converting the now offset Date object to a string
  const convertedDateStr = dateObjWithOffset.toLocaleString('en-US', {
    timeZone: 'UTC',
  });

  // Creating a string for the UTC offset
  const utcOffsetStr =
    utcOffset < 0 ? utcOffset.toString() : `+${utcOffset.toString()}`;

  return `${convertedDateStr} UTC${utcOffsetStr}`;
}

export { convertDateString };
